import React, { useEffect, useRef, useState } from 'react';

interface ReCaptchaV2Props { 
  siteKey: string;
  callback: (token: string) => void;
  expiredCallback: () => void;
}

const SCRIPT_ID = 'walletRecaptchaV2';
const RECAPTCHA_V2_SCRIPT_URL = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit';

const ReCaptchaV2: React.FC<ReCaptchaV2Props> = ({ siteKey, callback, expiredCallback }) => {
    const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

    const onRecaptchaLoadCallback = () => {
        if(!window.walletRecaptcha.grecaptchaV2) {
          window.walletRecaptcha.grecaptchaV2 = Object.assign(Object.create(Object.getPrototypeOf(window.grecaptcha)), window.grecaptcha)
          window.walletRecaptcha.grecaptchaV2.ready(() => {
            setIsRecaptchaLoaded(true);
          })
        }
    };

    const initWalletWindowRecaptchaObj = () => {
      if(!window.walletRecaptcha) {
        window.grecaptcha = undefined;
        window.walletRecaptcha = {};
      }
    }

    const removeRecaptchaV2ScriptIfExist = () => {
      const recaptchaV2Script = document.getElementById(SCRIPT_ID);
      if(recaptchaV2Script) {
        recaptchaV2Script.remove();
      }
    }

    const appendRecaptchaV2Script = () => {
      window.onRecaptchaLoad = onRecaptchaLoadCallback;
      const script = document.createElement('script');
      script.src = RECAPTCHA_V2_SCRIPT_URL;
      script.async = true;
      script.defer = true;
      script.id = SCRIPT_ID
      document.head.appendChild(script);
    }

    const clearRecaptcha = () => {
      removeRecaptchaV2ScriptIfExist();
      window.walletRecaptcha = undefined;
      window.onRecaptchaLoad = null;
      setIsRecaptchaLoaded(false);
    }

    useEffect(() => {
        initWalletWindowRecaptchaObj();

        if (!window.walletRecaptcha.grecaptchaV2) {
          removeRecaptchaV2ScriptIfExist();
          appendRecaptchaV2Script();
        } else if (window.walletRecaptcha.grecaptchaV2 && window.walletRecaptcha.grecaptchaV2.render) {
          onRecaptchaLoadCallback();
        }

        return () => {
          clearRecaptcha();
        };
    }, []);

    useEffect(() => {
      if (isRecaptchaLoaded) {
        window.walletRecaptcha.grecaptchaV2.render('recaptcha-widget-placeholder', {
            'sitekey': siteKey,
            'callback': callback,
            'expired-callback': expiredCallback
        });
      }
    }, [isRecaptchaLoaded]);

    return (
        <div id='recaptcha-widget-placeholder'></div>
    );
};
export default ReCaptchaV2;