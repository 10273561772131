import { Configuration } from "../configs/Configuration";
import { ApiClient, ApiUrls } from "./ApiClient";

const LogInformation = async (data: object | string) => {
  try {
    await ApiClient(ApiUrls.log(),{
      domain: Configuration.api_domain,
      method: "POST",
      body: {
        logInfo: data
      },
    });
  } catch (err) {
    throw err;
  }
};

export const LogService = {
  LogInformation
}