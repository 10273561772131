import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Modal } from "../../components/Modal/Modal";
import { Iframe3DSecurityForm } from "./Iframe3DSecurityForm";
import { reject3DSecurity } from "../../store/payment/PaymentActions";

import styles from "./Iframe3DSecurity.module.css";
import { IframeSPS3DSecurityForm } from "./IframeSPS3DSecurityForm";
import { MyText } from "../../components/MyText/MyText";
import { useTranslation } from "../../hooks/useTranslation";

export const Iframe3DSecurityModal = () => {
  const parameters = useSelector(
    (state: GlobalState) => state.payment.securityParameters
  );
  const url = useSelector((state: GlobalState) => state.payment.securityUrl);
  const providerName = useSelector(
      (state: GlobalState) =>
      state.configuration.walletConfiguration?.general.creditcard_provider.name
  );

  const iframeHeaderText = useTranslation("HEADER_3DS")

  const [isModalOpened, setModalOpened] = useState(false);

  const closeModal = () => {
    if (isModalOpened) {
      reject3DSecurity();
      setModalOpened(false);
    }
  };

  useEffect(() => {
    if (url) {
      setModalOpened(true);
    } else {
      closeModal();
    }
  }, [url]);

  if(!isModalOpened) {
    return null;
  }

  return (
    <Modal
      isOpen={isModalOpened}
      className={styles.modal}
      contentLabel="3d-security"
      data-test-id="3d-security-modal"
      onRequestClose={closeModal}
    >
      <MyText type="two" className={styles.iframeHeader}>
        {iframeHeaderText}
      </MyText>
      {providerName === "sps" ? (
        <IframeSPS3DSecurityForm url={url} />
      ) : (
        <Iframe3DSecurityForm url={url} parameters={parameters} />
      )}
    </Modal>
  );
};
