/** @jsx jsx */

import * as React from "react";
import { jsx } from "@emotion/core";

import { MyText } from "../../components/MyText/MyText";

import { Tracking } from "../../services/AnalyticsService/GoogleAnalyticsService";
import { ERROR_BORDER_COLOR } from "../../helpers/CreateStyleFromConfig";

import styles from "./CardAdd.module.scss";

type CardAddErrorType = {
  errorMessage: string
}

export const CardAddError: React.FunctionComponent<CardAddErrorType> = ({ errorMessage }) => {
  React.useEffect(() => {
    if (errorMessage) {
      Tracking.trackEvent(Tracking.EventAction.CreditCardError, errorMessage);
    }
  }, [errorMessage]);

  const color = ERROR_BORDER_COLOR;

  return (
    <MyText type="four" css={{ color }} className={styles.error}>
      {errorMessage}
    </MyText>
  );
};
