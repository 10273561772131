import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { maskArray } from "react-text-mask";
import { CreditCardManager } from "../containers/CreditCards/CreditCardManagerProvider";
import cardValidator from "card-validator";

import {
  isAmexCard,
  getCardType,
  isPotentiallyValid,
  isValidCardExpiration,
  isValidCardType,
  isValidCvv,
  isValidZip,
  NON_VALID_CARD,
  NOT_ACCEPTED_CARD, isDinersCard,
} from "../helpers/GetCardType";
import { getMonthYear } from "../helpers/DateHandler";

import { ApiClient, ApiUrls } from "../services/ApiClient";

import { useTranslation } from "./useTranslation";
import { parsePCIError } from "../helpers/ParseError";
import { getTokenId } from "../services/SPSService";
import { getMerchantConfig, MerchantsList } from "../helpers/helpers";
import { setAddCardFields, setFailureAddingCardId, setLoader } from "../store/payment/PaymentActions";
import { MyCheckWalletService } from "../services/MyCheckWalletService/WalletManager";
import { View } from "../services/MyCheckWalletService/WalletServiceTypes";
import { create_UUID } from "../helpers/CreateUUID";
import { getRecaptchaConfigs } from "../helpers/getRecaptchaConfigs";

const BACK_SPACE_KEY_CODE = 8;
const DELETE_KEY_CODE = 46;

export const CardValidationError = {
  status: "ERROR",
  code: 10,
  message: "CREDIT_CARD_DECLINED",
  info: "Your card was declined. Please use a different payment method"
};

export const CardFormInvalidError = {
  status: "ERROR",
  code: 20,
  message: "INVALID_CREDIT_CARD",
  info: "Credit card validation failed"
};

const ADD_CARD_FORM_READY_TRUE = "ADD_CARD_FORM_READY_TRUE";
const ADD_CARD_FORM_READY_FALSE = "ADD_CARD_FORM_READY_FALSE";

type Props = {
  isSps?: boolean
}

export function useCardAdd({ isSps = false }: Props) {
  const [cardNumber, setCardNumber] = React.useState("");
  const [cardType, setCardType] = React.useState("");
  const [monthYear, setMonthYear] = React.useState("");
  const [cvv, setCvv] = React.useState("");
  const [postal, setPostal] = React.useState("");
  const [storeCard, setStoreCard] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState("");
  const [numberError, setNumberError] = React.useState(false);
  const [cvvError, setCvvError] = React.useState(false);
  const [postalError, setPostalError] = React.useState(false);
  const [monthYearError, setMonthYearError] = React.useState(false);

  const [dirtyFields, setDirtyFields] = React.useState({ card: false, date: false, cvv: false, zip: false })
  const [touchedFields, setTouchedFields] = React.useState({ card: false, date: false, cvv: false, zip: false })

  const [cardFormCompleted, setCardFormCompleted] = React.useState(false);
  const [cardFormReadyWasTriggered, setCardFormReadyTrigger] = React.useState<string | null>(null);

  const cvvActive = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.credit_cards.cvv?.active);
  const isZipEnabled = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.credit_cards.zip_code.active);
  const acceptedCards = useSelector((state: GlobalState) => state.configuration.walletConfiguration.general.supported_card_types);
  const customConfig = useSelector((state: GlobalState) => state.configuration.customWalletConfiguration);
  const accessToken = useSelector((state: GlobalState) => state.account.accessToken);
  const isManagementModal = useSelector((state: GlobalState) => state.payment.managementModal);
  const isStoreCardEnabled = useSelector((state: GlobalState) => state.configuration.walletConfiguration.sections.credit_cards.temporary_card_support.active);

  const merchantConfigurations = useSelector((state: GlobalState) => state.configuration.walletConfiguration.merchantConfigurations);
  const merchantConfig = getMerchantConfig(MerchantsList.SPS_ECOMMERCE, merchantConfigurations) || {};
  const publishableKey = useSelector((state: GlobalState) => state.configuration.publishableKey);
  const inProgress = useSelector((state: GlobalState) => state.payment.loader);
  const isGuest = useSelector((state: GlobalState) => state.account.isGuest);
  const hasManageOpen = useSelector((state: GlobalState) => state.payment.managePaymentsShow);
  const isAdding = useSelector((state: GlobalState) => state.payment.isAdding);

  const errorLabelFilterCard = useTranslation("ERROR_FILTER_CARD");
  const errorCreditCard = useTranslation("ERROR_CREDIT_CARD_FIELD");
  const errorCreditCardNotSupported = useTranslation("CARD_NOT_SUPPORTED");
  const errorExpField = useTranslation("ERROR_EXP_FIELD");
  const errorCvvField = useTranslation("ERROR_CVV_FIELD");
  const errorZipField = useTranslation("ERROR_ZIP_FIELD");

  const recaptchaConfigs = getRecaptchaConfigs();
  const isCvvActive = !cvvActive && cvvActive !== undefined && cvvActive !== null ? false : true;

  const setInProgress = (isInProgress: boolean) => {
    dispatch(setLoader(isInProgress));
  }

  const setError = (errorMSG: string): void => {
    setErrorMessage(errorMSG);
    setNumberError(true);
  };

  const validateCardNumber = (event: any) => {
    const source = !!customConfig.acceptedCreditcards ? customConfig.acceptedCreditcards : acceptedCards
    const cardValidationObj = isPotentiallyValid(event.currentTarget.value, source);

    if (!cardValidationObj.isPotentiallyValid) {
      event.preventDefault();
      if (cardValidationObj.reason === NON_VALID_CARD) {
        setError(errorCreditCard);
      } else if (cardValidationObj.reason === NOT_ACCEPTED_CARD) {
        setError(errorCreditCardNotSupported);
      }
    }
  };

  const onKeyDownNumber = (event: any) => {
    const keyCode = event.keyCode;

    if (keyCode !== BACK_SPACE_KEY_CODE && keyCode !== DELETE_KEY_CODE) {
      validateCardNumber(event);
    }
  };

  const onChangeNumber = (event: any) => {
    setDirtyFields(prevState => ({ ...prevState, card: true }))
    CreditCardManager.onTyping();
    const value = event.currentTarget.value;
    setCardNumber(value);
    setCardType(value ? getCardType(value) : "");
    MyCheckWalletService.setCardIssuer(value && getCardType(value) ? getCardType(value).toUpperCase() : "");
  };

  const onCvvChange = (value: string) => {
    setDirtyFields(prevState => ({ ...prevState, cvv: true }))
    CreditCardManager.onTyping();
    setCvv(value);
  };

  const onMonthYearChange = (value: string) => {
    setDirtyFields(prevState => ({ ...prevState, date: true }))
    CreditCardManager.onTyping();
    setMonthYear(value);
  };

  const onPostalChange = (value: string) => {
    setDirtyFields(prevState => ({ ...prevState, zip: true }))
    CreditCardManager.onTyping()
    setPostal(value);
  };

  const onStoreCardChange = () => {
    CreditCardManager.onTyping()
    setStoreCard(!storeCard);
  };

  const onRecaptchaChange = (token: string | null) => {
    CreditCardManager.setRecaptchaToken(token);
  }

  const isSingleUse = (storeCard: boolean): number => {
    if (
      isManagementModal ||
      customConfig.view === View.MANAGE ||
      !isStoreCardEnabled ||
      isGuest ||
      (hasManageOpen && isAdding)
    ) {
      return 0;
    }
    return storeCard ? 0 : 1;
  };

  const onSpsCardAdd = async () => {
    const merchantId = customConfig.merchant_id ? customConfig.merchant_id : merchantConfig.id;

    const { data: { accessToken } } = await ApiClient(ApiUrls.fetchSPSMerchantConfiguration(publishableKey, merchantId));
    getTokenId(merchantConfig.settings.clientKey, cardNumber, monthYear, accessToken);

    CreditCardManager.setZip(postal);
    CreditCardManager.setEncryptedCvv({ clientKey: merchantConfig.settings.client_key, cvv, accessToken });
    CreditCardManager.setIsSingleUse(isSingleUse(storeCard));
  }

  const onCardAdd = async () => {
    if (inProgress) {
      return;
    }
    setInProgress(true);
    setErrorMessage("");

    setCardType(getCardType(cardNumber));
    const isInvalid = isError(true);

    if (!cardType || isInvalid) {
      setInProgress(false);
      CreditCardManager.onGetCardFailure(CardFormInvalidError);
      return;
    }

    if (recaptchaConfigs.isRecaptchaEnabled && !CreditCardManager.recaptchaToken) {
      setInProgress(false);
      setErrorMessage(CardFormInvalidError.info);
      CreditCardManager.onGetCardFailure(CardFormInvalidError);
      return;
    }

    if (
      (customConfig &&
        customConfig.acceptedCreditcards &&
        !customConfig.acceptedCreditcards.includes(cardType)) ||
      !acceptedCards.includes(cardType)
    ) {
      setErrorMessage(errorLabelFilterCard);
      setInProgress(false);
      CreditCardManager.onGetCardFailure(CardFormInvalidError);
      return;
    }

    try {
      if (isSps) {
        await onSpsCardAdd()
      }
    } catch (err) {
      // @ts-ignore
      setErrorMessage(parsePCIError(err.uniqueErrorIdentifier) || errorLabelFilterCard);
      setInProgress(false);
      CreditCardManager.onGetCardFailure(CardValidationError);
      // @ts-ignore
      if (err.uniqueErrorIdentifier) {
        MyCheckWalletService.cardAddFailureEvent(err);
      }
      if (recaptchaConfigs.isRecaptchaEnabled) {
        dispatch(setFailureAddingCardId(create_UUID()));
      }
      if (recaptchaConfigs.isRecaptchaV2 && !recaptchaConfigs.isEnterprise && !recaptchaConfigs.isRecaptchaV3) {
        window.walletRecaptcha.recaptchaV2.reset();
      }
    }
  };

  const onBlurError = (field: CardFormFields, submitValidation: boolean) => {
    if ((touchedFields[field] && dirtyFields[field]) || submitValidation) {
      switch (field) {
        case "card":
          return !isValidCardType(cardNumber, !!cardType)

        case "cvv":
          const isAmex = isAmexCard(cardNumber);
          return isCvvActive ? !isValidCvv(cvv, isAmex ? 4 : 3) : false;

        case "date":
          return !isValidCardExpiration(monthYear)

        case "zip":
          return isZipEnabled && !isValidZip(postal)
      }
    }

    return false
  }

  const isError = (submitValidation: boolean): boolean => {
    const isNumberError = onBlurError('card', submitValidation);
    const isCvvError = onBlurError('cvv', submitValidation);
    const isPostalError = onBlurError('zip', submitValidation);
    const isMonthYearError = onBlurError('date', submitValidation);
    setNumberError(isNumberError);
    setCvvError(isCvvError);
    setPostalError(isPostalError);
    setMonthYearError(isMonthYearError);

    const errorMessageStatus = { isNumberError, isMonthYearError, isCvvError, isPostalError };
    handleErrorMessage(errorMessageStatus);

    return isNumberError || isCvvError || isPostalError || isMonthYearError;
  };

  const isFormValid = (): boolean => {
    const isAmex = isAmexCard(cardNumber);
    const isNumberValid = isValidCardType(cardNumber, !!cardType);
    const isCvvValid = isCvvActive ? isValidCvv(cvv, isAmex ? 4 : 3) : true;
    const isZipValid = isValidZip(postal) || !isZipEnabled;
    const isDateValid = isValidCardExpiration(monthYear);

    return isNumberValid && isCvvValid && isZipValid && isDateValid;
  };

  const handleErrorMessage = (errorMessageStatus: ErrorMessageStatusType) => {
    const fieldsError = (Object.keys(errorMessageStatus) as Array<keyof ErrorMessageStatusType>).filter((key) => errorMessageStatus[key]);
    switch (fieldsError[0]) {
      case "isNumberError":
        if (cardValidator.number(cardNumber.replace(/_/g, "")).isValid) {
          setErrorMessage(errorCreditCardNotSupported);
        } else {
          setErrorMessage(errorCreditCard);
        }
        break;
      case "isMonthYearError":
        setErrorMessage(errorExpField);
        break;
      case "isCvvError":
        if(isCvvActive) {
          setErrorMessage(errorCvvField);
        }
        break;
      case "isPostalError":
        setErrorMessage(errorZipField);
        break;

      default:
        setErrorMessage("")
    }

  };

  const resetInputs = () => {
    setCardNumber("");
    setCardType("");
    setMonthYear("");
    setCvv("");
    setPostal("");
    setErrorMessage("");
  };

  const onCancel = () => {
    resetInputs();
  };

  const getDynamicMask = (min: number, max: number) => new RegExp(`[${min}-${max}]`);

  const monthYearMask = (rawValue: string): maskArray => {
    const values = getMonthYear(rawValue);
    const month = values.month.replace(/_/g, "");
    const year = values.year.replace(/_/g, "");

    const today = new Date();

    const currentYear = today.getFullYear().toString().substr(-2);
    const yearOne = parseInt(currentYear[0]);
    const yearTwo = parseInt(currentYear[1]);

    const currentMonth = today.getMonth() + 1;

    const getMonthDynamic = (minOne: number, maxOne: number) => {
      if (parseInt(month) < currentMonth && year[0] === currentYear[0]) {
        return [getDynamicMask(0, 1), getDynamicMask(minOne, maxOne), "/", getDynamicMask(yearOne, yearOne + 3), getDynamicMask(yearTwo + 1, 9)];
      }

      if (parseInt(year) < parseInt(currentYear)) {
        return [getDynamicMask(0, 1), getDynamicMask(minOne, maxOne), "/", getDynamicMask(yearOne, yearOne + 3), getDynamicMask(yearTwo, 9)];
      }

      return [getDynamicMask(0, 1), getDynamicMask(minOne, maxOne), "/", getDynamicMask(yearOne, yearOne + 3), getDynamicMask(0, 9)];
    };

    if (month[0] === "1") {
      return getMonthDynamic(0, 2);
    }

    return getMonthDynamic(1, 9);
  };

  const validateFieldOnBlur = (field: CardFormFields) => () => {
    if (dirtyFields[field]) {
      setTouchedFields({ ...touchedFields, [field]: true })
    }
  }

  const cardNumberMask = (rawValue: string): maskArray => {
    const isAmex = isAmexCard(rawValue);
    const isDiners = isDinersCard(rawValue);
    if (isAmex) {
      return [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/];
    }

    if (isDiners) {
      return [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
    }

    return [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
  };

  const getCardToken = async () => {
    try {
      await onCardAdd();
    } catch (error) {
      throw error;
    }
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    const addCardFields: CardFormFieldsValue = {
      cvv,
      monthYear: monthYear,
      isSingleUse: isSingleUse(storeCard),
      zip: postal,
      cardNumber: cardNumber.replace(/ /g, ""),
      cardType: cardType,
      errorLabel: errorLabelFilterCard,
    }
    if (recaptchaConfigs.isRecaptchaEnabled) {
      addCardFields.recaptchaToken = CreditCardManager.recaptchaToken;
    }

    if (isFormValid()) {
      setCardFormCompleted(true);
      dispatch(setAddCardFields(addCardFields));
      if (cardFormReadyWasTriggered === ADD_CARD_FORM_READY_FALSE) {
        CreditCardManager.onFormValid(isFormValid());
        setCardFormReadyTrigger(ADD_CARD_FORM_READY_TRUE);
      }
    } else {
      if (!cardFormReadyWasTriggered || cardFormReadyWasTriggered === ADD_CARD_FORM_READY_TRUE) {
        MyCheckWalletService.events.addCardFormReady(false);
        setCardFormReadyTrigger(ADD_CARD_FORM_READY_FALSE);
      }
      dispatch(setAddCardFields({}));
      setCardFormCompleted(false)
    }

    if (cardNumber && monthYear && cvv && postal && cardFormCompleted) {
      dispatch(setAddCardFields(addCardFields));
      CreditCardManager.onFormValid(isFormValid());
    }
  }, [errorMessage, cardNumber, cardType, monthYear, cvv, postal, storeCard, CreditCardManager.recaptchaToken]);

  React.useEffect(() => {
    isError(false)
  }, [touchedFields, dirtyFields, cardNumber, monthYear, cvv, postal])

  React.useEffect(() => {
    CreditCardManager.init(dispatch, true);
  }, []);

  return {
    getCardToken,

    onKeyDownNumber,
    onChangeNumber,
    onCvvChange,
    onPostalChange,
    onMonthYearChange,
    onStoreCardChange,
    onRecaptchaChange,
    onCancel,

    cardNumberMask,
    monthYearMask,

    isFormValid,
    validateFieldOnBlur,

    cardNumber,
    cardType,
    monthYear,
    cvv,
    postal,
    storeCard,

    errorMessage,
    numberError,
    cvvError,
    postalError,
    monthYearError,
  };
}
