import React from "react";
import ReactDOM from "react-dom";

import "core-js/features/promise";
import "core-js/features/string/starts-with";
import "core-js/features/object/assign";
import "core-js/features/object/values";
import "core-js/features/array/includes";
import "whatwg-fetch";

import "./assets";
import "./index.scss";
import { Root } from "./Root";
import { store } from "./store";
import { WalletEvent } from "./services/MyCheckWalletService/Wallet";
import { MyCheckWalletService } from "./services/MyCheckWalletService/WalletManager";
import { firebaseService } from "./services/FirebaseService";
import { getRecaptchaConfigs } from "./helpers/getRecaptchaConfigs";

export const WalletRootId = "mycheck-wallet-v3";
export const FormWalletRootId = "mycheck-wallet-v3-form";

const renderApp = () => {
  ReactDOM.render(<Root />, document.getElementById(WalletRootId));
};

const destroyApp = () => {
  const currentWindow: any = window;
  if (currentWindow.grecaptcha) {
    const reCaptchaConfigs = getRecaptchaConfigs();
    if(reCaptchaConfigs.isRecaptchaV3) {
      currentWindow.grecaptcha = undefined;
    }
  }
  const rootElement = document.getElementById(WalletRootId);

  const firebase = firebaseService;
  if (rootElement) {
    if (firebase && firebase.getFirebase() && firebase.getFirebase().apps && firebase.getFirebase().apps.length) {
      firebase.getFirebase().app().delete();
    }
    MyCheckWalletService.setWalletId("");

    store.dispatch({ type: "RESET_APP" });
    ReactDOM.unmountComponentAtNode(rootElement);
  }
};

window.addEventListener(WalletEvent.Init, renderApp);
window.addEventListener(WalletEvent.Destroy, destroyApp);
