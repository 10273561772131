import React, { useCallback, useEffect } from "react";

import { AppLoader } from "./containers/AppLoader";
import { InstantPaymentModal } from "./containers/InstantPayments/InstantPaymentModal";
import { Iframe3DSecurityModal } from "./services/3DSService/Iframe3DSecurityModal";
import { Checkout } from "./containers/Checkout/Checkout";
import { AppLogger } from "./components/AppLogger";
import { MyCheckWalletAuth } from "./services/MyCheckWalletService/WalletManager";
import { MyCheckAuthConnect } from "./services/MyCheckWalletService/WalletServiceTypes";
import { LogService } from "./services/LogService";

type AppType = {}

const App: React.FunctionComponent<AppType> = () => {
  const initialParams = MyCheckWalletAuth.getInitialParams() as MyCheckAuthConnect;

  const logMissingTokens = useCallback(async () => {
    if(!initialParams?.refreshToken && !initialParams?.accessToken) {
      await LogService.LogInformation({
        info: 'Initial Params refresh & access token missing',
        initialParams,
        windowObj: window?.mycheck?.auth
      });
    }
  }, [initialParams?.refreshToken, initialParams?.accessToken])

  useEffect(() => {
    logMissingTokens();
  }, [])

  return (
    <AppLoader 
      refreshToken={initialParams?.refreshToken}
      accessToken={initialParams?.accessToken}
      publishableKey={initialParams?.publishableKey!}
    >
      <AppLogger>
        <div className="mywallet-app" data-test-id="mywallet-app">
          <Checkout />
        </div>
        <InstantPaymentModal />
        <Iframe3DSecurityModal />
      </AppLogger>
    </AppLoader>
  );
};

export default App;
